import React, {useContext, useEffect, useMemo, useState} from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Step1 from "./steps/step-1";
import Step2 from "./steps/step-2";
import Step3 from "./steps/step-3";
import ProjectPanel from "../components/project-panel/project-panel";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Snackbar
} from "@mui/material";
import {CUSTOMER_ORDERS} from "../../../services/customer.service";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {Navigate} from "react-router";
import useJayda from "../../../hooks/useJayda";
import {ButtonIcape, ButtonIcapeOutlined} from "../../button/button-icape";
import '../calculators.scss';
import './calculator-flex.scss';
import CalculatorFlexContext from "../../../context/calculatorFlexProvider";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../../../tools/debug";
import {getAreaSize, mmToInch} from "../../../tools/unit.utils";
import debounce from "lodash.debounce";
import UserContext from "../../../context/UserProvider";
import {useTranslation} from "react-i18next";
import BackendConnection from "../../backend-connection/backend-connection";
import LockIcon from "@mui/icons-material/Lock";
import {createFilterOptions} from "@mui/material/Autocomplete";
import {generateNewAutoReference} from "../../../tools/auto-naming";
import {snackbarTransition} from "../../../tools/snackbarTransition";
import CalculatorStepper from "../components/calculator-stepper";
import StepNavigation from "../components/step-navigation";
import CalculatorToolbar from "../components/calculator-toolbar";
import {maxPcbCalculator} from "../../../tools/quantity-checks";
import {
    handleDevisorViewEvent,
    handleDownloadQuoteEvent,
    handleFirstInteractionEvent,
    handleOrderNowEvent,
    handleVirtualPageView
} from "../../../tools/google-tag-manager-events";
import {ErrorHandling} from "../../../tools/error-handling";
import {
    getPriceOfferData,
    getPriceOffersByCompany,
    updateQuoteExcludingPriceChange
} from "../../../services/quotes.service";

function CalculatorFlex() {
    const {t} = useTranslation();

    const steps = [`${t("calculators.step1.name")}`, `${t("calculators.step2.name")}`, `${t("calculators.step3.name")}`];
    /**
     * Calculator name / type
     * @type {string}
     */
    const calculator = 'flex';

    const axiosPrivate = useAxiosPrivate();
    const {sendJob} = useJayda(calculator);
    const {auth} = useAuth();
    const {company} = useContext(UserContext);
    const [openLoadPcbReferencePopup, setOpenLoadPcbReferencePopup] = useState(false);
    let _persist = false;

    const navigate = useNavigate();

    const {
        idOrder, setIdOrder,
        priceOfferId,
        priceMatrix, setPriceMatrix,
        repeatOrderMode, setRepeatOrderMode,
        repeatOrderCreationAt,
        activeStep, setActiveStep,
        completed, setCompleted,
        partNumber, setPartNumber,
        quantity, setQuantity,
        unitFormat, setUnitFormat,
        isLockFields, setIsLockFields,
        setIsJaydaPcbQuotable,
        userGerber, setUserGerber,
        jobParams,
        pcbImagesUrls,
        analyzeInProgress,
        progress,
        isErrorConnectToJayda, setIsErrorConnectToJayda,
        pcbDeliveryFormat,
        loadCurrency,
        setIsStepOneToFinalize,
        pcbLength, setPcbLength,
        pcbWidth, setPcbWidth,
        pcbLengthIn, setPcbLengthIn,
        pcbWidthIn, setPcbWidthIn,
        panelLength,
        panelWidth,
        panelLengthIn,
        panelWidthIn,
        pcbPerPanel, setPcbPerPanel,
        panelQuantity, setPanelQuantity,
        coverlayId, setCoverlayId,
        technologyId, setTechnologyId,
        smallestDrillingHoleSizeId, setSmallestDrillingHoleSizeId,
        tracksWidthAndSpaceId, setTracksWidthAndSpaceId,
        xoutId,
        fr4StiffenerId,
        stiffenerId,
        finishedInnerCuId,
        basedOuterCuId,
        polymideBaseMaterialThicknessId,
        rawMaterialCoreId,
        coreAdhesiveThicknessId,
        doubleSide3mTapeId,
        coverlayColorId, setCoverlayColorId,
        finishingKey,
        shippingMethodId,
        country,
        calendar, setCalendar,
        requestedDate,
        minDeliveryDate, setMinDeliveryDate,
        daysToDelivery, setDaysToDelivery,
        productionTime, setProductionTime,
        comment,
        priceOfferLocale, setPriceOfferLocale,
        priceOfferPdfUrl, setPriceOfferPdfUrl,
        shippingCost_PP, setShippingCost,
        unitPrice_PP, setUnitPrice_PP,
        stencil_PP, setStencil_PP,
        total_PP, setTotal_PP,
        initialTotalPricePP,
        incoterm_PP, setIncoterm_PP,
        voucher, setVoucher,
        setWeight,
        tariff, setTariff,
        batchSize, setBatchSize,
        connectToBO, setConnectToBO,
        errorBackendConnection, setErrorBackendConnection,
        customerReferences, setCustomerReferences,
        customerReferenceChoose, setCustomerReferenceChoose,
        repeatOrdersSelectedAvailable, setRepeatOrdersSelectedAvailable,
        isAvailableOrderSelected, setIsAvailableOrderSelected,
        productVariant, setProductVariant,
        orderToken,
        loadCustomerReferenceFlex, resetAllFlex
    } = useContext(CalculatorFlexContext);

    const {activeCurrency, setOrderCurrency, companyLocale} = useContext(UserContext);
    const {currencyCode} = useContext(UserContext);
    const [isReadyToBeSent, setIsReadyToBeSent] = useState(false);


    // GTM STATES
    const [firstInteraction, setFirstInteraction] = useState(true);
    const [onLoad, setOnLoad] = useState(true);

    // GTM EVENT : devisor_view
    const gtmCalculatorStepEvent = () => {
        handleDevisorViewEvent(calculator, pcbDeliveryFormat, activeStep);
    }

    // GTM VIEW : VirtualPageview
    useEffect(() => {
        handleVirtualPageView("calculator", activeStep);
    }, [activeStep]);


    // STATES FOR REPEAT ORDER
    const [openROAddReferenceDialog, setOpenROAddReferenceDialog] = React.useState(false);
    const [rOAddReferenceDialogValue, setROAddReferenceDialogValue] = React.useState({title: '', isNewReference: true});
    const [openCustomerPriceOffersList, setOpenCustomerPriceOffersList] = React.useState(false);
    let loadingForCustomerPriceOffers = openCustomerPriceOffersList && customerReferences.length === 0;
    const [loadingForCustomerPriceOffers2, setLoadingForCustomerPriceOffers2] = useState(false);
    const [isCustomerReferenceFieldEmpty, setIsCustomerReferenceFieldEmpty] = React.useState(false);
    const [addReferenceDialogCallingButton, setAddReferenceDialogCallingButton] = useState(null);
    const [isPcbRefAlreadyExisting, setIsPcbRefAlreadyExisting] = useState(false);
    const [isExcludingPriceChangeFieldsUpdated, setIsExcludingPriceChangeFieldsUpdated] = useState(false);

    // HANDLE COOKIE STORAGE AND SEND PRICE OFFER
    useEffect(() => {
        if (isReadyToBeSent) {
            localStorage.setItem(calculator, JSON.stringify({
                quantity,
                unitFormat,
                incoterm: incoterm_PP,
                pcb: {
                    pcbLength,
                    pcbWidth,
                    pcbLengthIn,
                    pcbWidthIn,
                    coverlayId,
                    technologyId,
                    smallestDrillingHoleSizeId,
                    tracksWidthAndSpaceId,
                    xoutId,
                    fr4StiffenerId,
                    stiffenerId,
                    finishedInnerCuId,
                    basedOuterCuId,
                    polymideBaseMaterialThicknessId,
                    rawMaterialCoreId,
                    coreAdhesiveThicknessId,
                    doubleSide3mTapeId,
                    coverlayColorId,
                    finishingKey,
                },
                panel: {
                    panelQuantity,
                    pcbPerPanel,
                    mm: {
                        panelLength,
                        panelWidth
                    },
                    in: {
                        panelLengthIn,
                        panelWidthIn,
                    }
                },
                shipping: {
                    shippingMethodId,
                    country,
                    daysToDelivery,
                    productionTime,
                    calendar,
                    requestedDate,
                    minDeliveryDate,
                },
                partNumber,
                currencyCode,
                loadCurrency,
                repeatOrder: {
                    repeatOrderMode,
                    repeatOrderCreationAt,
                    prices: {
                        unit: unitPrice_PP,
                        shipping: shippingCost_PP,
                        initialTotal: initialTotalPricePP,
                        total: total_PP
                    },
                    productVariant
                },
                isLockFields,
                pcbDeliveryFormat,
                userGerber,
                comment,
                priceOfferLocale
            }));

            if(Object.keys(country).length === 0){
                logInConsole("No country", 'red');
                setIsReadyToBeSent(false);
                return
            }

            if(isLockFields){
                sendPriceOffer()
            } else {
                checkQuantity() && sendPriceOffer()
            }

            setIsReadyToBeSent(false);
        }
    }, [isReadyToBeSent]);


    //debounce useEffect to avoid backend to be spammed
    const readyToSaveAndSend = () => {
        setIsReadyToBeSent(true);
    }
    const debouncePriceOffer = useMemo(() => debounce(readyToSaveAndSend, 800), []);
    // DEBOUNCE LAUNCHER
    useEffect(() => {
        if (onLoad) {
            setOnLoad(false);
        } else {
            if (firstInteraction) {
                // GTM EVENT : first_interaction
                handleFirstInteractionEvent(calculator, pcbDeliveryFormat);
                setFirstInteraction(false);
            }
        }
        debouncePriceOffer();
    }, [
        quantity,
        panelQuantity,
        unitFormat,
        currencyCode,
        pcbDeliveryFormat,
        pcbLength,
        pcbWidth,
        panelLength,
        panelWidth,
        pcbLengthIn,
        pcbWidthIn,
        panelLengthIn,
        panelWidthIn,
        pcbPerPanel,
        coverlayId,
        technologyId,
        smallestDrillingHoleSizeId,
        tracksWidthAndSpaceId,
        xoutId,
        fr4StiffenerId,
        stiffenerId,
        finishedInnerCuId,
        basedOuterCuId,
        polymideBaseMaterialThicknessId,
        rawMaterialCoreId,
        coreAdhesiveThicknessId,
        doubleSide3mTapeId,
        coverlayColorId,
        finishingKey,
        shippingMethodId,
        country,
        requestedDate,
        comment,
        priceOfferLocale]);
    // Stop the invocation of the debounced function after unmounting
    useEffect(() => {
        return () => {
            debouncePriceOffer.cancel();
        }
    }, []);



    // UTILS
    const handleNewProject = () => {
        handleUnlockFields();
        resetAllFlex();
        setPriceOfferLocale(companyLocale);
        setIsCustomerReferenceFieldEmpty(false);
    }
    const handleUnlockFields = () => {
        setCustomerReferenceChoose({title: '', isNewReference: false, isOrdered: false});
        setPartNumber('');
        setIsLockFields(false);

        if(repeatOrderMode.enabled) {
            logInConsole(`Leaving ${calculator} repeat order mode`, 'gold');
            if (JSON.parse(localStorage.getItem('cart'))?.checkout.calculator === calculator) {
                localStorage.removeItem('cart');
            }
            setProductVariant([]);
            setRepeatOrderMode({...repeatOrderMode, enabled: false, lockPrice: false})
        }
    }


    // HANDLE REFERENCE
    const handleValidLoadReference = async () => {

        setOpenLoadPcbReferencePopup(false);
        let referenceToLoad = customerReferences.find(obj => {
            return obj.pcbPartNumber === customerReferenceChoose.title;
        });

        setConnectToBO(true);
        if (referenceToLoad.isOrdered) {
            axiosPrivate.get(CUSTOMER_ORDERS + '/' + idOrder + '/repeatOrderInfos')
                .then(
                    (response) => {
                        logInConsole(`PCB details sent by backend :`, 'chocolate');
                        logInConsoleObject(response.data);
                        loadCustomerReferenceFlex(response.data, true, false);
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        setErrorBackendConnection(true);
                    }
                ).finally(
                () => {
                    setConnectToBO(false);
                }
            )

        }
        else {
            try {
                setConnectToBO(true);
                const priceOfferData = await getPriceOfferData(referenceToLoad.id, true);
                loadCustomerReferenceFlex(priceOfferData, false, false);
            }
            catch (error) {
                setErrorBackendConnection(true);
            }
            finally {
                setConnectToBO(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    };
    const handleCancelLoadReference = () => {
        setCustomerReferenceChoose({title: '', newReference: false, isOrdered: false});
        setOpenLoadPcbReferencePopup(false);
        setIsLockFields(false);
        setIsCustomerReferenceFieldEmpty(true);
        setRepeatOrdersSelectedAvailable([]);
        setIsAvailableOrderSelected(false);
    };
    const handleAddNewReference = (e) => {

        for (let i = 0; i < customerReferences.length; i++) {
            if (customerReferences[i].pcbPartNumber.toLowerCase().trim() === rOAddReferenceDialogValue.title.toLowerCase().trim()) {
                setIsPcbRefAlreadyExisting(true);
                return
            }
        }

        logInConsole("[handle] Add new reference : ", "grey");
        logInConsoleObject(rOAddReferenceDialogValue);
        setCustomerReferenceChoose(rOAddReferenceDialogValue);
        setPartNumber(rOAddReferenceDialogValue.title);
        setProductVariant([]);
        setOpenROAddReferenceDialog(false);
        setIsCustomerReferenceFieldEmpty(false);
    };




    // CHECKOUT
    let checkoutChoice = '';
    const handleDownloadQuote = (e) => {
        logInConsole("Downloading PDF - Normal", "grey");
        handleAddNewReference();
        checkoutProcess('downloadQuote');
    }
    const handleOrderNow = (e) => {
        handleAddNewReference()
        checkoutProcess('orderNow');
    }
    const [checkout, setCheckout] = useState(false);
    const handleOpenPdf = (_pdfUrl) => {
        // GTM EVENT : download_my_quote
        handleDownloadQuoteEvent(calculator, pcbDeliveryFormat);

        window.open(process.env.REACT_APP_BACKEND_BASE_URL + '/' + _pdfUrl);
    }
    const handlePriceOfferExcludingPriceChangeFields = async () => {
        try {
            setConnectToBO(true);
            const newFields = await updateQuoteExcludingPriceChange(auth.customerId, priceOfferId, comment, priceOfferLocale);
            setPriceOfferPdfUrl(newFields.priceOfferPdf);
        }
        catch (error) {
            setErrorBackendConnection(true);
        }
        finally {
            setConnectToBO(false);
        }
    }


    const sendPriceOffer = () => {
        if (!checkIfAllFieldsAreFilled() || analyzeInProgress === 'inProgress' || repeatOrderMode.lockPrice || isNaN(quantity)){
            return
        }
        let config = {headers: {"Content-Type": "application/json"}};
        let data = {
            "quantity": parseInt(quantity),
            unitFormat,
            "customerId": parseInt(auth.customerId),
            currencyCode: repeatOrderMode.lockPrice ? loadCurrency.code : currencyCode,
            "deliveryFormat" : pcbDeliveryFormat,
            persist: _persist,
            "countryId": country.id,
            "pcbLength": parseFloat(pcbLength),
            "pcbWidth": parseFloat(pcbWidth),
            "pcbLengthIn": parseFloat(pcbLengthIn),
            "pcbWidthIn": parseFloat(pcbWidthIn),
            "pcbPerPanel": pcbDeliveryFormat === 'single' ? 1 : parseInt(pcbPerPanel),
            "panel":{
                "panelQuantity": pcbDeliveryFormat === 'single' ? null : panelQuantity,
                "mm":{
                    "panelLength" : pcbDeliveryFormat === 'single' ? null : panelLength,
                    "panelWidth" : pcbDeliveryFormat === 'single' ? null : panelWidth
                },

                "in":{
                    "panelLengthIn" : pcbDeliveryFormat === 'single' ? null : panelLengthIn,
                    "panelWidthIn" : pcbDeliveryFormat === 'single' ? null : panelWidthIn,
                }
            },
            partNumber,
            pcbImagesUrls,
            solderMaskId : 1,
            coverlayId,
            technologyId,
            smallestDrillingHoleSizeId,
            tracksWidthAndSpaceId,
            xoutId,
            fr4StiffenerId,
            stiffenerId,
            finishedInnerCuId,
            basedOuterCuId,
            polymideBaseMaterialThicknessId,
            rawMaterialCoreId,
            coreAdhesiveThicknessId,
            doubleSide3mTapeId,
            solderMaskColorId: 1,
            coverlayColorId,
            finishingKey,
            requestedDate,
            shippingMethodId,
            comment,
            "localeCode": priceOfferLocale,
            "repeatOrder": repeatOrderMode.background,
        };

        setConnectToBO(true);
        axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/flex-quotations`, data, config)
            .then(
                (response) => {
                    logInConsoleSpacer();
                    logInConsole(`${calculator} quotation received :`, 'teal');
                    logInConsoleObject(response.data);

                    setConnectToBO(false);
                    setErrorBackendConnection(false);
                    let apiResponse = response.data;
                    setIncoterm_PP(apiResponse.incoterm.name);
                    setMinDeliveryDate(apiResponse.minDeliveryDate);
                    setDaysToDelivery(apiResponse.daysToDelivery);
                    setProductionTime(apiResponse.productionTime);
                    setPriceMatrix(apiResponse.priceMatrix);
                    setCalendar(apiResponse.calendar);



                    //Cart cookie
                    setShippingCost(apiResponse.shippingCost);
                    setStencil_PP(apiResponse.stencilPrice);
                    setUnitPrice_PP(apiResponse.unitPrice);
                    setTotal_PP(apiResponse.fullPrice);
                    setVoucher(apiResponse.vouchers)
                    setWeight(apiResponse.weight);
                    setTariff(apiResponse.tariff);
                    setBatchSize(apiResponse.batchSize);

                    if (_persist) {
                        if (checkoutChoice === 'orderNow' && apiResponse.productVariant) {
                            logInConsole(`Order now with product variant:`, 'steelblue');
                            logInConsoleObject(apiResponse.productVariant);

                            setProductVariant(apiResponse.productVariant);
                            window.scrollTo(0, 0);

                            // GTM EVENT : order_now
                            handleOrderNowEvent(calculator, pcbDeliveryFormat);
                        }
                        if (checkoutChoice === 'downloadQuote' && apiResponse.priceOfferPdf !== null && apiResponse.priceOfferPdf !== "" && _persist) {

                            handleOpenPdf(apiResponse.priceOfferPdf);
                        }
                        if (!repeatOrderMode.enabled) {
                            checkoutChoice = '';
                        }
                        repeatOrderMode.enabled && repeatOrderToCart();
                    }
                },
                (error) => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) ||
                        error.message || error.toString();
                    logInConsoleObject(resMessage);
                    setErrorBackendConnection(true);
                    ErrorHandling(calculator, resMessage);
                }
            ).finally(
            () => {
                setConnectToBO(false);
            }
        )
    };
    const checkoutProcess = (_checkoutChoice) => {
        if(errorBackendConnection || connectToBO){
            return;
        }

        setAddReferenceDialogCallingButton(_checkoutChoice);

        // CHECK IF PART NUMBER IS EMPTY
        if (customerReferenceChoose.title === '' && rOAddReferenceDialogValue.title === '') {
            setIsCustomerReferenceFieldEmpty(true);
            setOpenROAddReferenceDialog(true);
            setROAddReferenceDialogValue({title: generateNewAutoReference(company), isNewReference: true});
            return;
        }
        setIsCustomerReferenceFieldEmpty(false);


        // UPDATE STEPPER
        let newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);

        checkoutChoice = _checkoutChoice;

        setCheckout(true);
    }


    useEffect(async () => {
        if (checkout && partNumber !== '') {
            _persist = true;
            checkoutChoice = addReferenceDialogCallingButton;

            // CHECK LOAD QUOTE / REPEAT ORDER MODE
            if (repeatOrderMode.lockPrice) {
                setOrderCurrency(loadCurrency.symbol);

                repeatOrderToCart();
            }
            else {
                setOrderCurrency(activeCurrency);
                sendPriceOffer();
            }

            setCheckout(false);
        }
    }, [checkout, partNumber]);


    //PCB LOAD FORM TEXT FIELD
    const filter = createFilterOptions();
    useEffect(() => {
        let active = true;
        if (!loadingForCustomerPriceOffers) {
            return undefined;
        }

        (async () => {
            setLoadingForCustomerPriceOffers2(true)

            try {
                const priceOffers = await getPriceOffersByCompany(auth.customerId, "Flex");
                if (active) {
                    setCustomerReferences(priceOffers);
                }
            }
            catch (error) {
                setErrorBackendConnection(true);
            }
            finally {
                setLoadingForCustomerPriceOffers2(false);
            }

        })();

        return () => {
            active = false;
        }

    }, [loadingForCustomerPriceOffers]);


    // LOAD QUOTE / REPEAT ORDER FUNCTIONS
    /**
     * Updating quantity / delivery date breaks the locked price to get a new price from the price matrix.
     */
    const updateRepeatOrder = () => {
        if (repeatOrderMode.lockPrice) {
            logInConsole('Updating repeat order, price unlocked', 'gold');

            // TODO vider ou garder le même product variant ? voir si le back est capable de faire cette gestion
            setProductVariant([]);
            localStorage.removeItem("cart");
            setRepeatOrderMode({...repeatOrderMode, "lockPrice": false})
        }
    }
    const repeatOrderToCart = async () => {
        _persist = false;

        if (checkoutChoice === 'orderNow') {
            checkoutChoice = '';
            navigate('/cart', {replace: false});
        }
        if (checkoutChoice === 'downloadQuote') {

            // Check if locale of comment has been modified
            if (isExcludingPriceChangeFieldsUpdated){
                await handlePriceOfferExcludingPriceChangeFields();
                setIsExcludingPriceChangeFieldsUpdated(false);
            }

            logInConsole("Downloading PDF - Price locked", "grey");
            handleOpenPdf(priceOfferPdfUrl);
            checkoutChoice = '';
        }

    };



    // CHECKING STEP FIELDS
    const checkIfAllFieldsAreFilled = () => {
        return !(quantity === '' ||
            pcbWidth === '' ||
            pcbLength === '' ||
            coverlayId === '' ||
            technologyId === '' || technologyId === '-1' ||
            smallestDrillingHoleSizeId === '' ||
            tracksWidthAndSpaceId === '' ||
            (pcbDeliveryFormat !== 'single' && (panelWidth === '' || panelLength === ''))
        );
    }
    const stepOneToBeFilled = () => {
        setActiveStep(0)
        setIsStepOneToFinalize(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }



    //MAX AREA CHECK
    const [isPcbAreaOversized, setIsPcbAreaOversized] = useState(false);
    const checkQuantity = () => {
        const areaSize = getAreaSize(pcbWidth, pcbLength, pcbWidthIn, pcbLengthIn, pcbPerPanel, quantity, unitFormat);

        if (pcbDeliveryFormat === 'single') {
            if (quantity <= 0 || quantity === "" || isNaN(quantity)) {
                setQuantity(1);
                setPcbPerPanel(1);
                return false
            }
            if (quantity < pcbPerPanel) {
                setPcbPerPanel(quantity);
            }
            if ((unitFormat === 'mm' && areaSize >= 2) || (unitFormat === 'in' && areaSize >= 3100)) {
                setIsPcbAreaOversized(true);
                return false
            }
            setIsPcbAreaOversized(false);
            return true;
        }

        if (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') {
            if (panelQuantity <= 0 || panelQuantity === "" || isNaN(panelQuantity)) {
                setPanelQuantity(1);
                return false
            }
            if (pcbPerPanel > maxPcbCalculator(pcbWidth, pcbLength, panelWidth, panelLength) || pcbPerPanel === 0) {
                setPcbPerPanel(maxPcbCalculator(pcbWidth, pcbLength, panelWidth, panelLength));
                return false
            }
            if ((unitFormat === 'mm' && areaSize >= 2) || (unitFormat === 'in' && areaSize >= 3100)) {
                setIsPcbAreaOversized(true);
                return false
            }
            setIsPcbAreaOversized(false);
            return true;
        }

    }


    // PANEL QUANTITY AUTOMATION
    useEffect(() => {
        if((panelQuantity !== null && panelQuantity !== '') && (pcbPerPanel !== null && pcbPerPanel !== '') && (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom')){
            setQuantity(Math.floor(panelQuantity * pcbPerPanel));
        }
    }, [panelQuantity, pcbPerPanel, pcbDeliveryFormat])


    // JAYDA
    const [isJaydaPopupOpen, setIsJaydaPopupOpen] = useState(false);
    useEffect(() => {
        if (analyzeInProgress === 'inProgress') {

        }
        if (analyzeInProgress === 'ended') {
            sendPriceOffer();
        }
    }, [analyzeInProgress]);
    const handleDropFile = async (file) => {
        handleNewProject();
        setUserGerber(file);
        await sendJob(file);
    };
    const jaydaPcbNotQuotable = (fieldsOutsidePerimeter) => {
        setIsJaydaPcbQuotable(false);
        setIsJaydaPopupOpen(true);
        logInConsole("Fields outside perimeter:", 'orange');
        logInConsoleObject( fieldsOutsidePerimeter);
    }
    useEffect(() => {
        if (jobParams === undefined || jobParams === null) {
            return
        }
        logInConsole(`jobParams`, 'lightblue');
        logInConsoleObject(jobParams);

        if (Object.keys(jobParams).length !== 0) {

            // SIZE & NULL CHECK
            if (jobParams?.pcbWidth !== null && jobParams?.pcbLength !== null) {
                switch (true) {
                    case jobParams?.pcbWidth < 10 || jobParams?.pcbWidth > 480 :
                        jaydaPcbNotQuotable("PCB Width");
                        break;
                    case jobParams?.pcbLength < 15 || jobParams?.pcbLength > 580 :
                        jaydaPcbNotQuotable("PCB Length");
                        break;
                    default:
                        setPcbWidth(jobParams?.pcbWidth);
                        setPcbLength(jobParams?.pcbLength);
                        setPcbWidthIn(mmToInch(jobParams?.pcbWidth));
                        setPcbLengthIn(mmToInch(jobParams?.pcbLength));
                }
            }

            // TECHNOLOGIE ID
            if (jobParams?.technologyId !== null && jobParams?.technologyId !== -1){
                setTechnologyId(jobParams?.technologyId);
            } else {
                jaydaPcbNotQuotable("Technologie ID");
            }

            setCoverlayId(jobParams?.solderMaskId);
            setSmallestDrillingHoleSizeId(jobParams?.smallestDrillingHoleSizeId);
            setTracksWidthAndSpaceId(jobParams?.tracksWidthSpaceId);
        }
    }, [jobParams]);


    /**
     * Allow the cart to use the right product variant
     * Once the product variant is received from the backend, create a local storage item readable and mandatory for the cart
     */
    useEffect(() => {
        if(productVariant === null || productVariant === undefined || productVariant === '' || Object.keys(productVariant).length === 0) {
            return
        }
        localStorage.setItem('cart', JSON.stringify({
            productVariant,
            orderToken,
            voucher,
            checkout: {calculator: calculator, method: ""},
            repeatOrderMode
        }));
    }, [productVariant]);


    // CART REDIRECTION IF FLEX CART ACTIVE + LOCALE INITIALISATION
    useEffect(() => {
        const cartLocalStorage = JSON.parse(localStorage.getItem('cart'))
        if(cartLocalStorage?.productVariant.length > 0 && cartLocalStorage?.checkout.calculator === calculator) {
            setProductVariant(cartLocalStorage?.productVariant);
        }

        if (priceOfferLocale === ""){
            setPriceOfferLocale(companyLocale);
        }
    }, []);


    // CONSTRAINTS ----------------------------------------------------------

    //Coverlay color constraint
    useEffect(() => {
        let previousColorId = coverlayColorId;
        if( coverlayId === 1 || coverlayId === ''){
            setCoverlayColorId(1);
            return
        }
        if ( previousColorId === 1){
            setCoverlayColorId(2);
        }
    }, [coverlayId]);


    return (
        <Box className={repeatOrderMode.background ? "calculator-wrapper calculator-repeat-order" : "calculator-wrapper"}>
            {/*//once the product variant is received, go to /cart*/}
            {Object.keys(productVariant).length > 0 && !repeatOrderMode.enabled && <Navigate to="/cart" replace={false}/>}

            {/*PROGRESS BAR CONNECT TO BO*/}
            { connectToBO ? ( <BackendConnection/> ) : "" }


            {/* POPUP REPEAT ORDER */}
            <Dialog open={openLoadPcbReferencePopup} onClose={handleCancelLoadReference} aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className="alertTitle">
                    { repeatOrdersSelectedAvailable.length > 0 ? t("popup.repeatOrder.title") : "Load a reference" }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        {repeatOrdersSelectedAvailable.length > 0 ?
                            <FormControl>
                                <FormLabel id="repeat-orders-selected-radio-buttons-group-label" sx={{fontWeight:"bold"}}>{customerReferenceChoose.title}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="repeat-orders-selected-radio-buttons-group-label"
                                    name="repeat-orders-selected-radio-buttons-group"
                                    onChange={(e) => {setIdOrder(e.target.value); setIsAvailableOrderSelected(true)}}
                                >
                                    {
                                        repeatOrdersSelectedAvailable.map((order, key) => {

                                            const quantity = `${order.pcbQuantity} ${ order.pcbQuantity > 1 ? t("common.pcbs") : t("common.pcb")}`
                                            const orderReference = order.reference ? order.reference : "No order reference"

                                            const label = `${order.number} - ${orderReference} - ${quantity}`;

                                            return <FormControlLabel key={key} value={order.id} control={<Radio />} label={label} />
                                        })

                                    }
                                </RadioGroup>
                            </FormControl>
                            :
                            <>
                                <span style={{fontWeight: "bold"}}>
                                    {customerReferenceChoose.title} 
                                </span>
                                {t("popup.repeatOrder.content")}
                            </>
                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeOutlined onClick={handleCancelLoadReference}>
                        {t("cta.cancel")}
                    </ButtonIcapeOutlined>
                    <ButtonIcape onClick={handleValidLoadReference}
                                 disabled={ repeatOrdersSelectedAvailable.length > 0 && !isAvailableOrderSelected }>
                        {t("cta.load")}
                    </ButtonIcape>
                </DialogActions>
            </Dialog>

            {/* POPUP JAYDA */}
            <Dialog open={isJaydaPopupOpen} onClose={() => setIsJaydaPopupOpen(false)} aria-labelledby="Pcb not quotable">
                <DialogTitle>
                    {t("popup.jaydaCantQuote.title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("popup.jaydaCantQuote.content")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeOutlined onClick={() => setIsJaydaPopupOpen(false)}>Close</ButtonIcapeOutlined>
                    <ButtonIcape onClick={() => window.location.href = 'mailto:quickturn@icape-group.com'} autoFocus>
                        {t("cta.contactUs")}
                    </ButtonIcape>
                </DialogActions>
            </Dialog>

            {/* CALCULATOR FLEX */}
            <Box className="calculator">
                {/*SNACKBAR CONNECT TO BO FAILED*/}
                <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                          open={errorBackendConnection || isErrorConnectToJayda}
                          key='topright-backend'
                          sx={{ width: '100%' }}
                          autoHideDuration={10000}
                          TransitionComponent={snackbarTransition}
                          onClose={() => {
                              setErrorBackendConnection(false);
                              setIsErrorConnectToJayda(false);
                          }}>
                    <Alert severity="error" variant="filled"
                           onClose={() => {
                               setErrorBackendConnection(false);
                               setIsErrorConnectToJayda(false);
                           }}>
                        {t("popup.failedConnectBackend")}
                    </Alert>
                </Snackbar>

                {/*BATCHSIZE TOO HIGH*/}
                <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                          open={isPcbAreaOversized}
                          key='topright-batchsize'
                          sx={{ width: '100%' }}
                          autoHideDuration={6000}
                          TransitionComponent={snackbarTransition}>
                    <Alert severity="warning" variant="filled">
                        {t("calculators.toolbar.oversizeMessage")}&nbsp;
                        {unitFormat === "mm" ? "2m²" : "3 100in²"}
                    </Alert>
                </Snackbar>

                {/*TITLE*/}
                <Typography variant={'h1'}>
                    {t("calculators.flex.calculatorTitle")}
                </Typography>

                {/*STEPPER*/}
                <CalculatorStepper
                    jayda={{analyzeInProgress, progress}}
                    field={{checkIfAllFieldsAreFilled}}
                    step={{activeStep, setActiveStep, completed, steps}}
                />

                {/*STEP TITLE & TOOLBAR*/}
                <Box className="title-wrapper">
                    {/*STEP TITLE*/}
                    <Typography variant={'h2'}>
                        {steps[activeStep]}
                        {repeatOrderMode.background &&
                            <>
                                <br/>
                                <span>
                                    {/*TODO TRANSLATE*/}
                                    Repeat order process
                                </span>
                            </>
                        }
                    </Typography>

                    {/*TOOLBAR*/}
                    <CalculatorToolbar
                        autocomplete={{
                            setConnectToBO,
                            customerReferenceChoose,
                            openCustomerPriceOffersList, setOpenCustomerPriceOffersList,
                            customerReferences, setCustomerReferences,
                            loadingForCustomerPriceOffers2, loadingForCustomerPriceOffers,
                            isCustomerReferenceFieldEmpty,
                            setCustomerReferenceChoose,
                            setOpenROAddReferenceDialog,
                            setROAddReferenceDialogValue,
                            setIsCustomerReferenceFieldEmpty,
                            rOAddReferenceDialogValue,
                            isPcbRefAlreadyExisting, setIsPcbRefAlreadyExisting,
                            addReferenceDialogCallingButton,
                            openROAddReferenceDialog, setOpenLoadPcbReferencePopup,
                            setRepeatOrdersSelectedAvailable
                        }}
                        field={{
                            quantity, setQuantity,
                            panelQuantity, setPanelQuantity,
                            unitFormat, setUnitFormat,
                            setPartNumber,
                            isPcbAreaOversized,
                            lockCustomPanelizationFields: false,
                            isLockFields,
                            repeatOrderMode,
                            pcbDeliveryFormat,
                        }}
                        function={{
                            handleAddNewReference,
                            handleDownloadQuote,
                            handleNewProject,
                            handleOrderNow,
                            handleUnlockFields,
                            updateRepeatOrder,
                        }}
                    />
                </Box>


                {/*STEP RENDERER*/}
                <Box className={"content-wrapper"}>
                    {isLockFields && activeStep !== 2 &&
                        <Box className="lock-repeat-order">
                            <LockIcon />
                        </Box>
                    }
                    {activeStep === 0 ?
                        <Step1 handleNewProject={handleNewProject}
                               handleDropFile={handleDropFile}/>
                        : activeStep === 1 ?
                            <Step2/>
                            :
                            <Step3 updateRepeatOrder={updateRepeatOrder}
                                   setIsExcludingPriceChangeFieldsUpdated={{setIsExcludingPriceChangeFieldsUpdated}}/>
                    }
                </Box>

                {/*NEXT BUTTON DESKTOP*/}
                <StepNavigation
                    checkout={{checkoutProcess}}
                    device={{isDesktop: true}}
                    field={{checkIfAllFieldsAreFilled}}
                    google={{gtmCalculatorStepEvent}}
                    pcb={{isPcbAreaOversized}}
                    repeatOrderMode={{repeatOrderMode}}
                    service={{connectToBO}}
                    step={{activeStep, setActiveStep,
                        completed, setCompleted,
                        steps, setIsStepOneToFinalize,
                        stepOneToBeFilled}}
                />

            </Box>

            {/*PROJECT PANEL*/}
            <ProjectPanel prices={{
                pcbWidth, pcbLength,
                pcbWidthIn, pcbLengthIn,
                pcbPerPanel, panelQuantity,
                unitFormat, pcbDeliveryFormat,
                unitPrice_PP, total_PP, stencil_PP,
                quantity,
                incoterm_PP,
                requestedDate, minDeliveryDate,
                priceMatrix,
                repeatOrderMode, loadCurrency,
                country, tariff, batchSize
            }}/>


            {/*NEXT BUTTON TABLET MOBILE*/}
            <StepNavigation
                checkout={{checkoutProcess}}
                device={{isDesktop: false}}
                field={{checkIfAllFieldsAreFilled}}
                google={{gtmCalculatorStepEvent}}
                pcb={{isPcbAreaOversized}}
                repeatOrderMode={{repeatOrderMode}}
                service={{connectToBO}}
                step={{activeStep, setActiveStep,
                    completed, setCompleted,
                    steps, setIsStepOneToFinalize,
                    stepOneToBeFilled}}
            />
        </Box>
    );
}

export default CalculatorFlex;
