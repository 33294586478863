import {axiosPrivate} from "../api/axios";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../tools/debug";
import {ErrorHandling} from "../tools/error-handling";

/**
 * Filters Price Offers by Part Number.
 * Returns an array of Price Offers with unique PN
 * @param {array} priceOffers
 * @param {string} calculator
 * @returns {*[]}
 */
const filterPriceOffer = (priceOffers, calculator) => {
    logInConsole(`Filtering price offers by: ${calculator}`, 'chocolate');

    let referencesFilterred = [];
    for (let i = 0; i < priceOffers.length; i++) {
        let isPartNumberExists = false;

        // Filter for identical Part Number
        referencesFilterred.map(reference => {
            if (priceOffers[i].pcbPartNumber === reference.pcbPartNumber) {
                isPartNumberExists = true
            }
        })

        // Filter for only selected calculator
        priceOffers[i].pcbType === calculator && !isPartNumberExists && referencesFilterred.push(priceOffers[i] );
    }

    return referencesFilterred;

}


/**
 * Fetches all Price Offers from the customer's company.
 * Filters the result based on calculator if given.
 * Return an array of Price Offers filters or not.
 * @param {string} customerId
 * @param {string} calculator
 * @returns {Promise<*[]|any>}
 */
export const getPriceOffersByCompany = async (customerId, calculator) => {
    logInConsoleSpacer()
    logInConsole(`Fetching from backend : Quotes`, 'darkmagenta', 'bold');

    try {
        const priceOffers = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/${customerId}/price-offers-by-company`);

        if (priceOffers && priceOffers.data.length > 0) {
            logInConsole(`Customer quotes received`, 'green');
            logInConsoleObject(priceOffers.data);
        } else {
            logInConsole(`No customer quotes found (empty)`, 'orange');
        }
        return calculator ? filterPriceOffer(priceOffers.data, calculator) : priceOffers.data

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving quotes --->  ${resMessage}`, 'red');
        ErrorHandling(calculator, resMessage);

        throw error;
    }
}

/**
 * Fetches all data from a selected Price Offer.
 * @param {int} priceOfferId
 * @param {boolean} includeTechnicalDetails
 * @returns {Promise<any>}
 */
export const getPriceOfferData = async (priceOfferId, includeTechnicalDetails) => {
    logInConsoleSpacer();
    logInConsole(`Fetching from backend : Quote ${priceOfferId} data`, 'darkmagenta', 'bold');

    try {
        const priceOfferData = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/${includeTechnicalDetails}/${priceOfferId}/price-offer`)

        logInConsole(`Quote data received`, 'green');
        logInConsoleObject(priceOfferData.data);

        return priceOfferData.data;

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving quote data --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Fetches all PCB data.
 * @param {int} priceOfferId
 * @returns {Promise<any>}
 */
export const getPcbDetails = async (priceOfferId) => {
    logInConsoleSpacer();
    logInConsole(`Fetching from backend : PCB Details for Quote ${priceOfferId}`, 'darkmagenta', 'bold');

    try {
    const pcbDetails = await axiosPrivate.get(`/shop/pcb-details-from-price-offer/${priceOfferId}`)

    logInConsole(`PCB Details received`, 'green');
    logInConsoleObject(pcbDetails.data);

    return pcbDetails.data;

}
    catch (error) {

    const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    logInConsole(`Error retrieving PCB Details --->  ${resMessage}`, 'red');
    ErrorHandling(null, resMessage);

    throw error;
}
}

/**
 * Udpate quote excludind price change fields.
 * Comment or locale code.
 * @param {int} customerId
 * @param {int} priceOfferId
 * @param {string} comment
 * @param {string} localeCode "en_US"
 * @returns {Promise<any>}
 */
export const updateQuoteExcludingPriceChange = async (customerId, priceOfferId, comment, localeCode) => {
    logInConsoleSpacer();
    logInConsole(`Updating quote excluding price change for customer ${customerId} and price offer ${priceOfferId}`, 'darkmagenta', 'bold');

    const data = {comment, 'localeCode': localeCode}

    try {
        const newFields = await axiosPrivate.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/${customerId}/${priceOfferId}/update-loaded-quote`, data);
        logInConsole(`Quote updated successfully`, 'green');
        logInConsoleObject(newFields.data);
        return newFields.data;
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error updating quote excluding price change ---> ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;

    }
}